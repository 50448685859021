<template>
  <div class="modal-backdrop" >
    <div class="modal">
      <div class="modal-content">
        <slot></slot>
        <p v-if="title" class="modal-title">{{ title }}</p>
        <p v-if="message" class="modal-message">{{ message }}</p>
        <button class="btn" @click="$emit('close')">ЗАКРЫТЬ</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    title: {
      type: String,
      reqired: false,
    },
    message: {
      type: String,
      reqired: false,
    },
  },
};
</script>

<style scoped>
.modal {
  overscroll-behavior: contain;
  position: fixed;
  top: 90px;
  width: auto;  
  height: 80%;
  padding: 1rem;
  background: rgba(255, 255, 255,0.90);
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  overflow-y: initial !important;
  overflow-x: hidden;
  backdrop-filter: blur(3px);
}
.modal-content {
  padding: 30px 0;
  gap: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-backdrop {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
}
.modal-title {
  font-weight: 500;
  font-size: 35px;
  line-height: 120%;
  color: #252b42;
  text-align: center;
}
.modal-message {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #252b42;
  max-width: 85%;
  text-align: left;
  word-wrap: normal;
  white-space: pre-wrap;
  font-variant-numeric: tabular-nums;
}

@media (max-width: 950px) {
  .modal {
    width: 600px;
    height: 80vh;
  }
}
@media (max-width: 620px) {
  .modal {
    width: 100%;
  }
}
@media (max-width: 620px) {
  .modal-message {
    font-size: 18px;
  }
}
@media (max-width: 490px){
  .modal-title {
    font-size: 25px;
  }
}
@media (max-width: 368px){
  .modal-content{
    padding: 0;
  }
  .modal-title {
    font-size: 20px;
  }
}
</style>
