<template>
  <section class="calc">
    <div class="_container">
      <h3>Рассчитайте стоимость бетона</h3>
      <div class="controls">
        <div class="inp__elem tovar">
          <label for="tovar">Выбери тип изделия</label>
          <select name="tovar" id="tovar" v-model="option">
            <option v-for="item in calcPriceList" :value="item.price">{{item.name}}</option>
          </select>
        </div>
        <p>X</p>
        <div class="inp__elem volume">
          <label for="volume">Объём, м3</label>
          <input
            type="number"
            name="volume"
            min="0"
            max="100000"
            id="volume"
            class="inp"
            placeholder="100"
            v-model="volume"
          />
        </div>
        <p class="equals">=</p>
        <div class="inp__elem sum">
          <label for="sum">Рассчетная стоимость</label>
          <input
            type="number"
            name="sum"
            id="sum"
            class="inp"
            placeholder="0₽"
            v-model="sum"
            readonly
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { calcPriceList } from '@/utils/prices';
import { ref, computed} from 'vue';
export default {
  setup() {
    const option = ref(4050);
    const volume = ref(0);
    const sum = computed(()=>{
      return option.value * volume.value
    })
    return {calcPriceList, option, volume, sum};
  },
};
</script>

<style scoped>
.calc {
  margin-top: 15px;
  width: 100%;
  height: 170px;
  background: #c63636;
}
.controls {
  display: flex;
  gap: 15px;
}
.controls p {
  color: #fff;
  font-size: 35px;
  text-align: center;
  align-self: center;
}
.inp__elem.volume {
  width: 300px;
}
.inp__elem {
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 16px;
  border: 1px solid #cfd3d4;
  border-radius: 8px;
  width: 100%;
}
.inp__elem > * {
  font-size: 16px;
}
._container {
  padding: 15px 5px;
}
._container h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 120%; /* 36px */
}

@media (max-width: 1023px) {
  .calc {
    height: auto;
  }
}
@media (max-width: 839px) {
  p {
    display: none;
  }
  .controls {
    flex-wrap: wrap;
  }
  .inp__elem.volume {
    width: 100%;
  }
  .controls > * {
    width: 100%;
  }
}
@media (max-width: 666px) {
  ._container h3 {
    text-align: center;
  }
}
</style>
