<template>
  <app-navbar @open-form="openForm"  />
  <main>
    <modal-window v-if="formModal" @close="closeForm" class="form"> <app-form/> </modal-window>
    <section-banner />
    <section-contacts/>
    <section-about/>
    <section-partners/>
    <!-- <section-certs/> -->
    <section-call />
  </main>
  <app-footer/>
</template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import SectionBanner from "@/components/Sections/SectionAboutBanner.vue";
import SectionCall from "@/components/Sections/SectionCall.vue";
import SectionContacts from "@/components/Sections/SectionContacts.vue";
import SectionAbout from "@/components/Sections/SectionAbout.vue"
import SectionPartners from "@/components/Sections/SectionPartners.vue";
import SectionCerts from "@/components/Sections/SectionCerts.vue"
import AppFooter from "@/components/AppFooter.vue"
import ModalWindow from "@/components/modalWindow.vue";
import AppForm from "@/components/AppForm.vue";

import { ref } from "vue";
export default {
  name: "AboutView",
  components: {
    "app-navbar": AppNavbar,
    "section-banner": SectionBanner,
    "section-call": SectionCall,
    "section-contacts":SectionContacts,
    "section-about":SectionAbout,
    "section-partners": SectionPartners,
    "section-certs": SectionCerts,
    "app-footer" : AppFooter,
    'app-form' : AppForm,
    'modal-window' : ModalWindow
  },
  setup(){
    const formModal = ref(false);
    const closeForm = () => {
      formModal.value = false;
    };
    const openForm = () => {
      formModal.value = true;
    }
    return{
      formModal,
      closeForm,
      openForm,
    }
  },
};
</script>
