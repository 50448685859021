<template>
  <footer class="footer">
    <div class="_container">
      <div class="left">
        <p>Реквизиты компании</p>
        <br />
        <p>(ООО «Трансбетон»)</p>
        <p>ОГРН 1123332000791</p>
        <p>Р/С 40702810810000012518</p>
        <p>ИНН/КПП 3305714573/330501001</p>
      </div>
      <div class="right">
        <p>Контактная информация:</p>
        <br />
        <p>
          Эл. почта
          <a href="mailto:rbu1kovrov@yandex.ru">rbu1kovrov@yandex.ru</a>
        </p>
        <p>ТЕЛ. <a href="tel:+74923254526">+7(49232)5-45-26</a></p>
        <a href="tel:+74923258844">+7(49232)5-88-44</a>
        <a href="tel:+79040356333">7(904)03-56-333</a>
        <p>© 2022, ООО «Трансбетон»</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  background: #1f1f1f;
}
._container{
    display: flex;
    padding: 15px 5px;
}
.left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  color: #c7c7c7;
  font-family: Comfortaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: right;
  color: #c7c7c7;
  font-family: Comfortaa;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
@media (max-width:767px){
    ._container{
        flex-wrap: wrap;
    }
    .left, .right{
        width: 100%;
        text-align: center;
        justify-content: center;
    }

}
</style>
