<template>
  <section class="about">
    <div class="_container">
      <h2><span>КОМПАНИЯ</span> ООО «ТРАНСБЕТОН»</h2>
      <div class="info">
        <div class="info_row">
          <div class="img">
            <iframe
              width="530"
              height="450"
              src="https://www.youtube.com/embed/SYqrWlNYL3I"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="info_row">
          <div class="text">
            <p>
              Наша компания сочетает в себе передовые технологии, высокую
              экспертизу и щепетильное отношение к каждому заказу, чтобы
              обеспечить нашим клиентам надежность и долговечность в каждом
              проекте.
              <br><br>
              Специалисты тщательно контролируют весь процесс производства,
              начиная от подготовки сырья и заканчивая готовыми изделиями, чтобы
              гарантировать соответствие всем необходимым стандартам и
              требованиям клиентов.
              <br><br>
            </p>
            <p>
              Нам присущ индивидуальный подход к каждому клиенту. Мы стремимся
              понять потребности наших заказчиков и предоставить решения,
              которые наиболее соответствуют их требованиям.
              <br><br>
              Компания "ТрансБетон" - ваш надежный партнер в строительстве. Мы
              гарантируем превосходное качество, надежность и оперативность
            </p>
          </div>
        </div>

        
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.about {
  background: #1f1f1f;
}
._container {
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
}
._container h2 {
  color: #fff;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin: 20px 0;
}
._container h2 span {
  color: #c63636;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.info_row {
  display: flex;
  gap: 30px;
}
.img {
  position: relative;
  width: 100%;
  height: 420px;
}

.img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom right;
}

.img iframe {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom right;
  border: 3px solid #fff;
}
.text {
  width: 100%;
  height: auto;
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  line-height: 150%; /* 33.6px */
}
.info_row:last-child {
  margin-bottom: 30px;
}
@media (max-width: 1023px) {
  .text {
    font-size: 18px;
    height: auto;
  }
}
@media (max-width: 820px) {
  .info_row {
    flex-wrap: wrap;
    justify-content: center;
    /* text-align: center; */
  }
  .img iframe {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom right;
  }
  ._container h2 {
    color: #fff;
    font-size: 45px;
    text-align: center;
  }
}
@media (max-width: 534px) {
  .text {
    width: 100%;
  }
  .img {
    width: 400px;
    height: 250px;
  }
}

</style>
