const sheben = [
    {
        name : 'БЕТОН М - 100',
        price: 'Цена 4050 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 150',
        price: 'Цена 4250 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 200',
        price: 'Цена 4450 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 250',
        price: 'Цена 4800 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 300',
        price: 'Цена 5150 ₽ за 1 м3'
    },
];

const gravel = [
    {
        name : 'БЕТОН М - 200',
        price: 'Цена 6100 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 250',
        price: 'Цена 6450 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 300',
        price: 'Цена 6750 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 350',
        price: 'Цена 7100 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 400',
        price: 'Цена 7450 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 450',
        price: 'Цена 7800 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 500',
        price: 'Цена 8150 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 550',
        price: 'Цена 5150 ₽ за 1 м3'
    },
]

const rastvor = [
    {
        name : 'РАСТВОР М - 50',
        price: 'Цена 3 900 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 100',
        price: 'Цена 4050 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 150',
        price: 'Цена 4450 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 200',
        price: 'Цена 4650 ₽ за 1 м3'
    },
    {
        name : 'ИЗВЕСТКОВЫЙ',
        price: 'Цена 3750 ₽ за 1 м3'
    },
]

const priceList = [sheben, gravel, rastvor];

const calcPriceList = [
    {
        name : 'БЕТОН М - 100 ЩЕБЕНЬ',
        price: 4050
    },
    {
        name : 'БЕТОН М - 150 ЩЕБЕНЬ',
        price: 4250
    },
    {
        name : 'БЕТОН М - 200 ЩЕБЕНЬ',
        price: 4450
    },
    {
        name : 'БЕТОН М - 250 ЩЕБЕНЬ',
        price: 4800
    },
    {
        name : 'БЕТОН М - 300 ЩЕБЕНЬ',
        price: 5150
    },
    {
        name : 'БЕТОН М - 200 ГРАВИЙ',
        price: 6100
    },
    {
        name : 'БЕТОН М - 250 ГРАВИЙ',
        price: 6450
    },
    {
        name : 'БЕТОН М - 300 ГРАВИЙ',
        price: 6750
    },
    {
        name : 'БЕТОН М - 350 ГРАВИЙ',
        price: 7100
    },
    {
        name : 'БЕТОН М - 400 ГРАВИЙ',
        price: 7450
    },
    {
        name : 'БЕТОН М - 450 ГРАВИЙ',
        price: 7800
    },
    {
        name : 'БЕТОН М - 500 ГРАВИЙ',
        price: 8150
    },
    {
        name : 'БЕТОН М - 550 ГРАВИЙ',
        price: 5150
    },
    {
        name : 'РАСТВОР М - 50',
        price: 3900
    },
    {
        name : 'РАСТВОР М - 100',
        price: 4050
    },
    {
        name : 'РАСТВОР М - 150',
        price: 4450
    },
    {
        name : 'РАСТВОР М - 200',
        price: 4650
    },
    {
        name : 'РАСТВОР ИЗВЕСТКОВЫЙ',
        price: 3750
    },
]

export {priceList, calcPriceList};