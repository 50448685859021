<template>
  <section class="contacts">
    <div class="_container">
      <h3>Контакты для моментальной связи</h3>
      <div class="items">
        <div class="item">
          <img src="../../assets/geo.svg" alt="geolocation" class="img" />
          <p>
            Владимирская обл., г. Ковров,<br />ул. Муромская, д. 24 строение 2
          </p>
        </div>
        <div class="item">
          <img src="../../assets/phone.svg" alt="phone" class="img" />
          <a href="tel:+74923254526">+7(49232)5-45-26</a>
        </div>
        <div class="item">
          <a href="tel:+74923258844">+7(49232)5-88-44</a>
        </div>
        <div class="item">
          <a href="tel:+79040356333">+7(904)03-56-333</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.contacts {
  width: 100%;
  height: 170px;
  background: #c63636;
}

._container {
  padding: 15px 5px;
}
._container h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 120%; /* 36px */
}
.items {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
.item {
  display: flex;
  gap: 5px;
  align-items: center;
}
.item a,
p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 120%; /* 26.4px */
}
.item img {
  width: 45px;
  height: 45px;
}

@media (max-width: 1023px) {
  .contacts {
    height: auto;
  }
  .items {
    gap: 30px 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .item:first-child {
    flex: 1 1 100%;
  }
}
@media (max-width: 666px) {
  .item {
    position: relative;
    padding-left: 50px;
  }
  .item img {
    position: absolute;
    left: 0;
    top: 0;
  }
  .item a,
  p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 110%; /* 26.4px */
  }
  ._container h3 {
    text-align: center;
  }
}
</style>
