<template>
  <section class="advantages">
    <div class="_container">
      <h2><span>КАЧЕСТВО</span> ЗАЛОГ УСПЕХА</h2>
      <div class="items">
        <div class="item">
          <div class="dot">
            <img src="../../assets/medal.svg" loading="lazy" alt="Лидер по производству и поставке бетона" />
          </div>
          <p>
            Более 19 лет на рынке – ЛИДЕР по производству и поставке бетона во
            Владимирской области
          </p>
        </div>
        <div class="item">
          <div class="dot">
            <img src="../../assets/search.svg" loading="lazy" alt="испытательная лаборатория" />
          </div>
          <p>
            Предприятие имеет аккредитованную испытательную лабораторию, где проверяется качество выпускаемой продукции
          </p>
        </div>
        <div class="item">
          <div class="dot">
            <img src="../../assets/certificate.svg" loading="lazy" alt="medal" />
          </div>
          <p>
            Изготовление продукции по ГОСТ 7473-2010, ГОСТ 28013-98
          </p>
        </div>
        <div class="item">
          <div class="dot">
            <img src="../../assets/car.svg" loading="lazy" alt="medal" />
          </div>
          <p>
            Собственный автопарк, где представлены миксеры, самосвалы, погрузчики, трактора, краны
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin: 20px 0;
}
h2 span {
  color: #c63636;
}
.items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
}
.item {
  display: flex;
  align-items: center;
  color: #000;
  font-family: Comfortaa;
  font-size: 21px;
  font-weight: 400;
  line-height: 120%;
  gap: 15px;
}
.item p {
    width: 80%;
}
.dot {
  display: flex;
  width: 85px;
  height: 85px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #c63636;
}
.dot img{
    width: 60px;
    height: 60px;
}

@media (max-width: 960px){
    .items{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 674px){
    h2{
        text-align: center;
        font-size: 45px;
        margin-bottom: 35px;
    }
    .dot{
        width: 65px;
        height: 65px;
    }
    .dot img{
        width: 45px;
        height: 45px;
    }
    .item p {
        font-size: 18px;
    }
}
</style>
