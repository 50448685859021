<template>
  <modal-window v-if="termsModal" title="Политика обработки персональных данных" :message="termsDesc" @close="closeTerms" />
  <form @submit.prevent="onSubmit" class="form">
    <div v-if="formSubmited" class="cover">
      <h3>Заявка в обработке</h3>
        <p>Благодарим за то что вы с нами!</p>
        <p>Наши специлисты свяжутся с вами в кратчайшие сроки!</p>
    </div>
    <h3>Оставьте заявку</h3>
    <div class="inp__elem" :class="{ danger: nameMessage }">
      <label for="name"
        >Ваше имя <small v-if="nameMessage">{{ nameMessage }}</small>
      </label>
      <input
        type="text"
        name="name"
        id="name"
        class="inp"
        placeholder="Иван иванов"
        v-model="name"
        @blur="nameBlur"
      />
    </div>
    <div class="inp__elem" :class="{ danger: emailMessage }">
      <label for="email"
        >Email <small v-if="emailMessage">{{ emailMessage }}</small>
      </label>
      <input
        type="email"
        name="email"
        id="email"
        class="inp"
        placeholder="transtbeton@yandex.ru"
        v-model="email"
        @blur="emailBlur"
      />
    </div>
    <div class="inp__elem" :class="{ danger: phoneMessage }">
      <label for="phone"
        >Номер телефона <small v-if="phoneMessage">{{ phoneMessage }}</small>
      </label>
      <input
        type="text"
        name="phone"
        id="phone"
        class="inp"
        placeholder="8023456789"
        v-model="phone"
        @blur="phoneBlur"
      />
    </div>
    <div class="cb__elem">
      <input
        type="checkbox"
        class="custom-checkbox"
        id="terms2"
        name="terms2"
        :value="true"
        v-model="terms2"
        @blur="terms2Blur"
      />
      <label for="terms2"></label>
      <p>Я согласен с <span @click="openTerms" >политикой обработки персональных данных </span></p>
    </div>
    <button class="btn">ОСТАВИТЬ ЗАЯВКУ</button>
  </form>
</template>

<script>
import * as yup from "yup";
import { ref } from "vue";
import { useField, useForm } from "vee-validate";
import { useEmail } from "@/use/mail";
import modalWindow from "./modalWindow.vue";
import termsDesc from "@/utils/terms";

export default {
  setup() {
    const { handleSubmit, isSubmitting } = useForm();
    const formSubmited = ref(false);
    const emailHandler = useEmail();

    const termsModal = ref(false);
    const closeTerms = () => {
      termsModal.value = false;
    };
    const openTerms = () => {
      termsModal.value = true;
    }

    // name
    const {
      value: name,
      errorMessage: nameMessage,
      handleBlur: nameBlur,
    } = useField(
      "name",
      yup.string().required("*Это обязательное поле").trim()
    );

    // phone
    const phoneRegx = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    const {
      value: phone,
      errorMessage: phoneMessage,
      handleBlur: phoneBlur,
    } = useField(
      "phone",
      yup
        .string()
        .required("*Это обязательное поле")
        .matches(phoneRegx, "*Некорректный номер")
    );
    // email
    const {
      value: email,
      errorMessage: emailMessage,
      handleBlur: emailBlur,
    } = useField(
      "email",
      yup
        .string()
        .required("*Это обязательное поле")
        .email("*Введите корректный email")
    );
    //terms
    const {
      value: terms2,
      errorMessage: terms2Message,
      handleBlur: terms2Blur,
    } = useField(
      "terms2",
      yup
        .boolean()
        .required("*Это обязательное поле")
        .oneOf([true], "*Это обязательное поле")
    );

    const createSubmit = () => {
      if (terms.value) {
        const data = {
          name: name?.value,
          phone: phone?.value,
          email: email?.value,
          date: new Date(Date.now()).toLocaleDateString(),
        };
        name.value = "";
        phone.value = "";
        email.value = "";
        // console.log(data);
        formSubmited.value = true;
        let res = emailHandler.send(data);
      }
    };

    const onSubmit = handleSubmit(createSubmit);

    return {
      name,
      nameMessage,
      nameBlur,
      phone,
      phoneMessage,
      phoneBlur,
      email,
      emailMessage,
      emailBlur,
      terms2,
      terms2Message,
      terms2Blur,
      handleSubmit,
      isSubmitting,
      formSubmited,
      onSubmit,
      termsModal,
      openTerms,
      closeTerms,
      termsDesc
    };
  },
  components: {
    'modal-window': modalWindow,
  }
};
</script>

<style scoped>

small {
  color: #c63636;
}
.inp__elem.danger {
  border-color: #c63636;
}
.form {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  background: #ffffff;
  padding: 37px 43px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  border: 1px solid #dde2e5;
  flex: 0 0 463px;
}

.form h3 {
  color: #2b2f32;
  font-family: Comfortaa;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}
.inp__elem {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 16px;
  border: 1px solid #cfd3d4;
  border-radius: 8px;
  width: 100%;
}
.inp__elem > * {
  font-size: 16px;
}
.cb__elem {
  display: flex;
  margin-top: 20px;
  margin-bottom: 18px;
}
.cb__elem p span {
  cursor: pointer;
  color: #c63636;
  text-decoration: underline;
}
.custom-checkbox {
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  text-align: left;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  border-color: #c63636;
  background-color: #c63636;
  background-image: url("../assets/tick.svg");
  background-size: cover;
}
.cover{
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
  z-index: 3;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.cover h3{
  font-size: 30px;
}
.cover p {
  font-size: 20px;
}
@media (max-width: 900px) {
  .form {
    flex: 0 1 463px;
  }
}
</style>
