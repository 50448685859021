<template>
  <section class="call">
    <img src="../../assets/call.webp" loading="lazy" alt="Transbeton" class="banner__img" />
    <div class="_container">
      <app-form/>
      <div class="text">
        <h2>Оставьте заявку онлайн</h2>
        <p>
          Наш специалист свяжется с вами в кратчайшие сроки для уточнения
          деталей вашего заказа
        </p>
        <p>
          Доставка осуществляется в сроки, определенные заявкой, любыми
          объемами, высококвалифицированными водителями.
        </p>
        <p>
          Наше предприятие организует выезд специалистов на объект для
          консультаций по вопросам доставки, расчетам, подбор марки бетона.
        </p>

        <div class="dots">
          <div class="item">
            <div class="dot"><img class="dot_img" src="../../assets/skidka.svg" loading="lazy" alt="Скидка 5% на заказ"></div>
            <p>Скидка 5% на заказ</p>
          </div>

          <div class="item">
            <div class="dot"><img class="dot_img" src="../../assets/clock.svg" loading="lazy" alt="Выполнение в назначеные сроки"></div>
            <p>Выполнение в назначеные сроки</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppForm from "../AppFormBottom.vue";

export default {
  setup() {
    return {};
  },
  components: {
    "app-form": AppForm,
  },
};
</script>

<style scoped>
.text {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.text p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}
.dots {
  display: flex;
  gap: 30px;
}
.item{
  display: flex;
  align-items: center;
  gap: 5px;
}
.item p{
  font-size: 18px;
  text-align: center;
}
.dot {
  display: flex;
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #c63636;
}
.dots .item .dot img{
  width: 100%;
  height: 100%;
}
.call {
  width: 100%;
  height: 600px;
  position: relative;
  z-index: 0;
  /* margin-top: 30px; */
}
.call .banner__img{
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  object-fit: cover;
  object-position: top;
  filter: brightness(0.2);
}

._container {
  position: relative;
  z-index: -1;
  padding: 30px 5px;
  display: flex;
  gap: 40px;
}
._container h2 {
  color: #fff;
  font-size: 55px;
  font-weight: 400;
  line-height: 120%; /* 72px */
  max-width: 718px;
}
@media (max-width: 1000px) {
  .call {
    height: auto;
  }
  ._container {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  ._container h2{
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  .text{
    justify-content: center;
    text-align: center;
  }
  .dots{
    justify-content: center;
  }
  ._container h2 {
    text-align: center;
  }
}
@media (max-width: 580px) {
  ._container {
    padding: 30px 0;
  }
  ._container > * {
    width: 100%;
    max-width: 100%;
  }
  ._container h2 {
    font-size: 40px;
  }
  .dots{
    flex-wrap: wrap;
  }
  .item{
    flex-wrap: wrap;
    justify-content: center;
  }
  .item p{
    gap: 5px;
    font-size: 16px;
  }
  .dot{
    width: 45px;
    height: 45px;
  }
}
</style>
