<template>
  <section class="banner">
    <img src="../../assets/banner.webp" alt="Transbeton" class="banner__img" />
    <div class="_container">
      <h1>
        Изготовление и поставка бетона и раствора на строительные площадки
        Коврова и Ковровского района
      </h1>
      <app-form/>
    </div>
  </section>
</template>

<script> 
import AppForm from '../AppForm.vue';

export default {
  setup() {
    return {};
  },
  components:{
    'app-form' : AppForm,
  }
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 600px;
  position: relative;
  z-index: 0;
}
.banner img {
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  object-fit: cover;
  object-position: top;
  filter: brightness(0.3)
}

._container{
  position: relative;
  z-index: -1;
  padding: 30px 5px;
  display: flex;
  gap: 40px;
}
._container h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 400;
  line-height: 120%; /* 72px */
  max-width: 718px; 
}
@media (max-width: 1000px){
  .banner{
    height: auto;
  }
  ._container{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  ._container h1{
    text-align: center;
  }
}
@media (max-width: 580px){
  ._container{
    padding: 30px 0;
  }
  ._container > *{
    width: 100%;
    max-width: 100%;
  }
  ._container h1{
    font-size: 40px;
  }
}
</style>
