<template>
  <section class="prod">
    <div class="_container">
      <h2><span>ТРАНСПОРТНОЕ</span> ОБЕСПЕЧЕНИЕ</h2>
      <div class="items">
        <div class="item">
          <div class="item__img">
            <img src="../../assets/prod1.webp" loading="lazy" alt="Трансбетон" />
          </div>
          <ul>
            <li>
              <p>
                Завод имеет собственный автопарк, где представлены
                миксеры (автобетоносмесители) разных объемов от 2м3 до
                12м3,самосвалы, погрузчики, трактора, краны
              </p>
            </li>
            <li>
              <p>
                На миксерах имеются гидравлические лотки, которые позволяют
                корректировать длину лотка, угол поворота (вправо-влево) подача
                гидролотком обеспечивает качественную подачу бетона, растворов
                на объектах различной сложности. Длина лотков от 3м до 9м
              </p>
            </li>
            <li>
              <p>
                Транспорт обладает высокой маневренностью, обеспечивает
                непрерывность подачи, высокую производительность.
              </p>
            </li>
          </ul>
        </div>

        <div class="item">
          <div class="item__img">
            <img src="../../assets/prod2.webp" loading="lazy" alt="Трансбетон" />
          </div>
          <ul>
            <li>
              <p>
                Доставка осуществляется в сроки, определенные заявкой, любыми
                объемами, высококвалифицированными водителями
              </p>
            </li>
            <li>
              <p>
                На предприятии трудятся грамотные, ответственные сотрудники,
                каждый из которых четко исполняет свои задачи
              </p>
            </li>
            <li>
              <p>
                Наше предприятие организует выезд специалистов на объект для
                консультаций по вопросам доставки, расчетам, подбор марки бетона
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>

h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin-top: 60px;
  margin-bottom: 30px;
}
h2 span {
  color: #c63636;
}
.items {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 330px;
  padding: 20px;
  gap: 30px;
  background: #c63636;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.item:last-child {
  flex-direction: row-reverse;
}
.item__img {
  height: 100%;
  width: 530px;
}
.item__img img {
  height: 100%;
  width: 100%;
}
.item ul {
  width: 580px;
}
.item ul li {
  position: relative;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 120%; /* 22.8px */
}
.item ul li::before {
  content: "•";
  position: absolute;
  top: 0px;
  left: -15px;
}
.item ul li:not(:last-child) {
  margin-bottom: 15px;
}
@media (max-width:1023px){
    .item{
        height: auto;
        flex-wrap: wrap;
    }
    .item ul {
        width: 100%;
    }
    .item li p {
        height: auto;
    }
    .item__img img{
        object-fit: cover;
        object-position: left top;
    }
}
@media (max-width:675px){
    h2{
        text-align: center;
        font-size: 45px;
        margin-bottom: 35px;
    }
}
@media (max-width:400px){
    .item li p {
        font-size: 16px;;
    }
}
</style>
