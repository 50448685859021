<template>
    <app-navbar @open-form="openForm"  />
    <main>
      <modal-window v-if="formModal" @close="closeForm" class="form"> <app-form/> </modal-window>
      <section-banner />
      <section-contacts/>
      <section-zbprice/>
      <section-call />
    </main>
    <app-footer/>
  </template>
  
  <script>
  import AppNavbar from "@/components/AppNavbar.vue";
  import SectionBanner from "@/components/Sections/SectionZbBanner.vue";
  import SectionCall from "@/components/Sections/SectionCall.vue";
  import SectionContacts from "@/components/Sections/SectionContacts.vue";
  import AppFooter from "@/components/AppFooter.vue"
  import SectionZbprice from "@/components/Sections/SectionZbprice.vue";

  import ModalWindow from "@/components/modalWindow.vue";
  import AppForm from "@/components/AppForm.vue";

  import { ref } from "vue";
  export default {
    name: "ZbView",
    components: {
      "app-navbar": AppNavbar,
      "section-banner": SectionBanner,
      "section-call": SectionCall,
      "section-contacts":SectionContacts,
      "section-zbprice" : SectionZbprice,
      "app-footer" : AppFooter,
      'app-form' : AppForm,
      'modal-window' : ModalWindow
    },
    setup(){
    const formModal = ref(false);
    const closeForm = () => {
      formModal.value = false;
    };
    const openForm = () => {
      formModal.value = true;
    }
    return{
      formModal,
      closeForm,
      openForm,
    }
  },
  
  };
  </script>
  