const termsDesc = `Кто мы: ООО "Трансбетон"
Наш адрес сайта: transbeton.ru\n
\n1.2. Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей информации, которую сайт может получить от Пользователя во время использования им сайта ekkotara.ru (далее — Сайт).
\n1.3. Использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
\n1.4. Настоящая Политика применима только к transbeton.ru и не контролирует и не несет ответственность за сайты третьих лиц, на которые пользователь может перейти по ссылкам, доступным на сайте ekkotara.ru. На таких сайтах у пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия.
\n1.5. Сайт в общем случае не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако сайт исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в формах настоящего ресурса, и поддерживает эту информацию в актуальном состоянии.
\n\n2. Персональная информация пользователей, которую получает и обрабатывает сайт transbeton.ru
В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
\n2.1. Персональная информация, которую пользователь предоставляет о себе самостоятельно при оставлении заявки,подписке на рассылку, совершении покупки, регистрации (создании учётной записи) или в ином процессе использования сайта. Обязательная для предоставления информация помечена специальным образом. Иная информация предоставляется пользователем на его усмотрение.
\n2.2. Технические данные, которые автоматически собираются программным обеспечением сайта и передаются в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к сайту), время доступа, адрес запрашиваемой страницы. Эта информация анализируется программно в обезличенном виде для анализа посещаемости Сайта, и используется при разработке предложений по его улучшению и развитию. Связь между IP-адресом и персональной информацией Пользователя никогда не раскрывается третьим лицам, за исключением тех случаев, когда это требуется законодательством.
\n2.3. Данные, которые предоставляются сайту, в целях осуществления оказания услуг и/или
продаже товара и/или предоставления иных ценностей для посетителей сайта, в соответствии с
деятельностью настоящего ресурса:
электронная почта
в некоторых случаях телефон
ip адрес
\n\n3. Цели сбора и обработки персональной информации пользователей
Сайт собирает и хранит только те персональные данные, которые необходимы для оказания услуг и/или продаже товара и/или предоставления иных ценностей для посетителей сайта ekkotara.ru Персональную информацию пользователя можно использовать в следующих целях:
\n3.1. Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования сайта, оказания услуг, а также обработка запросов и заявок от Пользователя, направление информационных и тематических рассылок.
\n3.2. Улучшение качества Cайта, удобства его использования, разработка новых товаров и услуг
\n3.3. Таргетирование рекламных материалов
\n3.4. Проведение статистических и иных исследований на основе обезличенных предоставленных данных
\n3.5. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.
\n3.6. Создания учетной записи, если Пользователь дал согласие на создание учетной записи. 4.2.10. Уведомления Пользователя Сайта о состоянии заявки.
\n3.7. Предоставления Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Сайта
\n3.8. Осуществления рекламной деятельности с согласия Пользователя.
\n\n4. Условия обработки персональной информации пользователя и её передачи третьим лицам
\n4.1. Сайт хранит персональную информацию пользователей в соответствии с внутренними регламентами конкретных сервисов.
\n4.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу лиц.
\n4.3. Сайт серьезно относится к защите персональных данных Пользователя и никогда не предоставляет персональную информацию Пользователя кому бы то ни было, кроме случаев, когда этого прямо предусмотрено законодательством Российской Федерации. При обработке персональных данных пользователей сайт руководствуется Федеральным законом РФ «О персональных данных». Вся персональная информация Пользователя используется для связи с ним, для исполнения сделки, заключенной между Пользователями Сайта с помощью ресурсов Сайта, для анализа посещаемости Сайта, для разработки предложений по его улучшению и развитию, и может быть раскрыта иным третьим лицам только с его разрешения.
\n4.4. Сайт вправе передать персональную информацию пользователя третьим лицам в следующих случаях:
\n4.4.1. Пользователь выразил свое согласие на такие действия, путем согласия выразившегося в предоставлении таких данных;
\n4.4.2. Передача необходима в рамках использования пользователем определенного сервиса, либо для предоставления товаров и/или оказания услуги пользователю;
\n4.4.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;
\n4.4.4. В целях обеспечения возможности защиты прав и законных интересов сайта или третьих лиц в случаях, когда пользователь нарушает Пользовательское соглашение Сайта ekkotara.ru.
\n\n5. Изменение пользователем персональной информации
\n5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть, а также параметры её конфиденциальности, оставив заявление в адрес администрации сайта следующим способом:
с помощью формы обратной связи: transbeton.ru
по электронной почте, написав письмо transbeton@yandex.ru
\n5.2. Пользователь может в любой момент, отозвать свое согласие на обработку персональных данных, оставив заявление в адрес администрации сайта следующим способом:
с помощью формы обратной связи: transbeton.ru
по электронной почте, написав письмо transbeton@yandex.ru
\n5.3. Пользователь может в любой момент, отказаться от подписки, в каждом письме дана ссылка, с помощью которой это можно сделать. Также можно удалить себя их рассылки , оставив заявление в адрес администрации сайта следующим способом:
с помощью формы обратной связи: transbeton.ru
по электронной почте, написав письмо transbeton@yandex.ru
В течение 48 часов ваши данные будут удалены.
\n\n6. Меры, применяемые для защиты персональной информации пользователей
Сайт принимает необходимые и достаточные организационные и технические меры для защиты персональной информации пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
\n\n7. Изменение Политики конфиденциальности. Применимое законодательство
\n7.1. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности без предварительного уведомления пользователей Сайта.. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на странице по адресу transbeton. Работой с Сайтом Пользователь подтверждает свое согласие с Политикой конфиденциальности и ее новой редакцией в случае наличия изменений.
\n7.2. К настоящей Политике и отношениям между пользователем и сайтом, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.
\n\n8. Обратная связь. Вопросы и предложения
Все предложения или вопросы по поводу настоящей Политики следует направлять следующим способом:
с помощью формы обратной связи: transbeton.ru
по электронной почте, написав письмо transbeton@yandex.ru
\nДата публикации: Август 2023 г

`

export default termsDesc