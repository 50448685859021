<template>
  <app-navbar @open-form="openForm"  />
  <main>
    <modal-window v-if="formModal" @close="closeForm" class="form"> <app-form/> </modal-window>
    <div class="contacts">
      <div class="_container">
        <h2><span>КОНТАКТНАЯ</span> ИНФОРМАЦИЯ</h2>
        <div class="content">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2210.4806615825973!2d41.3504086127499!3d56.356042545255434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414c49b96baf78fb%3A0xa0823f3da2cfc7a1!2z0JzRg9GA0L7QvNGB0LrQsNGPINGD0LsuLCAyNCwg0JrQvtCy0YDQvtCyLCDQktC70LDQtNC40LzQuNGA0YHQutCw0Y8g0L7QsdC7LiwgNjAxOTE2!5e0!3m2!1sru!2sru!4v1691264477038!5m2!1sru!2sru"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="info">
            <p>АДРЕС:<br />601916 Россия, г.Ковров ул. Муромская 24 стр.2</p>
            <br />
            <p>ТЕЛЕФОНЫ:</p>
            <a href="tel:+74923254526">+7(49232)5-45-26</a> <br>
            <a href="tel:+74923258844">+7(49232)5-88-44</a> <br>
            <a href="tel:+79040356333">+7(904)03-56-333</a>
            <br>
            <br>
            <p>EMAIL:</p>
            <a href="mailto:rbu1kovrov@yandex.ru">rbu1kovrov@yandex.ru</a>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
import ModalWindow from "@/components/modalWindow.vue";
import AppForm from "@/components/AppForm.vue";

import { ref } from "vue";
export default {
  name: "ZbView",
  components: {
    "app-navbar": AppNavbar,
    "app-footer": AppFooter,
    'app-form' : AppForm,
    'modal-window' : ModalWindow
  },
  setup(){
    const formModal = ref(false);
    const closeForm = () => {
      formModal.value = false;
    };
    const openForm = () => {
      formModal.value = true;
    }
    return{
      formModal,
      closeForm,
      openForm,
    }
  },
};
</script>

<style scoped>
.contacts {
  height: auto;
}
._container h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin: 35px 0;
}
._container h2 span {
  color: #c63636;
}
.content {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.info {
  color: #000;
  font-family: Comfortaa;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */

}
@media(max-width:1023px){
    .content{
        flex-wrap: wrap;
        justify-content: center;
    }
    ._container h2{
        text-align: center;
    }
}
@media(max-width:630px){
    ._container h2{
        font-size: 35px;
    }
    .map iframe{
        width: 425px;
    }
    .info{
        text-align: center;
    }
}
@media(max-width:424px){
    
    .map iframe{
        width: 318px;
    }
    .info{
        font-size: 20px;
    }
}
</style>
