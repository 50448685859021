<template>
  <header>
    <nav class="navbar">
      <div class="_container">
        <div class="brand" @click="router.push('/')"><h2>ТРАНСБЕТОН</h2></div>

        <div class="controls">
          <div class="controls__item">
            <router-link class="underline" to="/about">О компании</router-link>
          </div>
          <div class="controls__item">
            <router-link class="underline" to="/zb">Ж/Б изделия</router-link>
          </div>
          <div class="controls__item">
            <router-link class="underline" to="/contacts">Контакты</router-link>
          </div>
          <div class="controls__item">
            <button class="btn" @click="this.$emit('openForm')" aria-label="Оставить заявку">
              ОСТАВИТЬ ЗАЯВКУ
            </button>
          </div>
        </div>

        <div class="burgermenu">
          <input class="checkbox" type="checkbox" v-model="isBurger" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
        </div>

        <transition name="burger-menu">
          <ul class="burger__links" v-if="isBurger">
            <li class="burger__link underline" @click="isBurger = false">
              <router-link to="/about">О компании</router-link>
            </li>
            <li class="burger__link underline" @click="isBurger = false">
              <router-link to="/zb">Ж/Б изделия</router-link>
            </li>
            <li class="burger__link underline" @click="isBurger = false">
              <router-link to="/contacts">Контакты</router-link>
            </li>
          </ul>
        </transition>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const isBurger = ref(false);
    const router = useRouter()
    return { isBurger , router};
  },
  emits: ['openForm'],
};
</script>

<style scoped>
.burger-menu-enter-active,
.burger-menu-leave-active {
  transition: opacity 0.35s ease;
}
.burger-menu-enter-from,
.burger-menu-leave-to {
  opacity: 0;
}
.burger__links {
  background-color: rgba(249, 249, 249, 0.96);
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 375px;
  height: 100vh;
  padding-top: 100px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}
.burger__link {
  font-size: 24px;
  font-weight: 500;
}
.burger__link:not(:last-child) {
  margin-bottom: 30px;
}
.burgermenu {
  display: none;
}
.navbar {
  min-height: 70px;
  background: #fff;
  position: sticky;
  z-index: 5;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
}
.navbar ._container {
  display: flex;
  gap: 25px 25px;
  height: 70px;
}
.brand {
  display: flex;
  height: 100%;
  min-width: 245px;
  background: #c63636;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.brand h2 {
  font-size: 27px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.controls__bMenu {
  display: none;
}

@media (max-width: 1000px) {
  .controls__item .btn {
    display: none;
  }
}
@media (max-width: 767px) {
  .controls {
    display: none;
  }
  .burgermenu {
    display: block;
  }
  .burgermenu .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    right: 20px;
    z-index: 100;
    opacity: 0;
    cursor: pointer;
  }
  .burgermenu .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 20px;
    right: 3%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .burgermenu .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
  }

  .burgermenu .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .burgermenu .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .burgermenu .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .burgermenu input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .burgermenu input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .burgermenu input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}
</style>
