import * as emailjs from "@emailjs/browser"

export function useEmail(){
    emailjs.init('IIf3CPFYQgK3gcX2I')

    const send = async (params) => {
        try{
            const response = await emailjs.send('service_s2xueyo','template_jrzclrm', params);
            return response
        }
        catch(error){
            return error;
        }
    }
    return{
        send
    }
}