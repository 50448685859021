<template>
  <section class="partners">
    <div class="_container">
      <h2>НАШИ <span>ПАРТНЕРЫ</span></h2>
      <div class="items">
        <p>ООО СЗ «СК Континент» г. Ковров</p>
        <p>ООО «Градострой» Аскона ДСК г.Ковров</p>
        <p>НПО «Вавилон» г.Ковров</p>
        <p>ООО «СК»Монолит» г. Москва</p>
        <p>ООО «СЗ»Аскона Инвест»</p>
        <p>ООО «Интера» г.Ковров</p>
        <p>ООО «ОлимпСитиСтрой» г.Москва</p>
        <p>ООО «СпецСтрой» г.Нижний Новгород</p>
        <p>ООО «Эккотара» г.Ковров</p>
      </div>
      <p class="desc">
        Качество, надежность, исполнительность, гибкий подход к каждому клиенту
        - девиз нашего предприятия ООО «Трансбетон»
      </p>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin-top: 60px;
  margin-bottom: 40px;
}
h2 span {
  color: #c63636;
}
.items {
  display: flex;
  gap: 30px 60px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
._container     .items p {
  color: #000;
  font-family: Oswald;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 36px */
}
.desc{
    margin-top: 30px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  margin-bottom: 30px;
}
@media (max-width:675px){
    h2{
        text-align: center;
        font-size: 45px;
        margin-bottom: 35px;
    }
}
</style>
