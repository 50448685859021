<template>
  <app-navbar @open-form="openForm" />
  <main>
    <modal-window v-if="formModal" @close="closeForm" class="form"> <app-form/> </modal-window>
    <section-banner />
    <section-contacts />
    <section-prices />
    <section-calc />
    <section-adv />
    <section-prod />
    <section-partners />
    <section-call />
  </main>
  <app-footer/>
</template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import SectionBanner from "@/components/Sections/SectionBanner.vue";
import SectionContacts from "@/components/Sections/SectionContacts.vue";
import SectionPrices from "@/components/Sections/SectionPrices.vue";
import SectionCalc from "@/components/Sections/SectionCalc.vue";
import SectionAdvantages from "@/components/Sections/SectionAdvantages.vue";
import SectionProd from "@/components/Sections/SectionProd.vue";
import SectionPartners from "@/components/Sections/SectionPartners.vue";
import SectionCall from "@/components/Sections/SectionCall.vue";
import ModalWindow from "@/components/modalWindow.vue";
import AppFooter from "@/components/AppFooter.vue"
import AppForm from "@/components/AppForm.vue";
import { ref } from "vue";

export default {
  name: "MainView",
  components: {
    "app-navbar": AppNavbar,
    "section-banner": SectionBanner,
    "section-contacts": SectionContacts,
    "section-prices": SectionPrices,
    "section-calc": SectionCalc,
    "section-adv": SectionAdvantages,
    "section-prod": SectionProd,
    "section-partners": SectionPartners,
    "section-call": SectionCall,
    "modal-window": ModalWindow,
    'app-form' : AppForm,
    "app-footer" : AppFooter
  },
  setup(){
    const formModal = ref(false);
    const closeForm = () => {
      formModal.value = false;
    };
    const openForm = () => {
      formModal.value = true;
    }
    return{
      formModal,
      closeForm,
      openForm,
    }
  }
};
</script>
