<template>
  <section class="certs">
    <div class="_container">
      <h2><span>ГАРАНТИИ</span> ОТ ПРОИЗВОДИТЕЛЯ</h2>
      <div class="items">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin: 40px 0;
}
h2 span {
  color: #c63636;
}
.items {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.item {
  height: 404px;
  width: 343px;
  background: #DEDEDE;
}
@media(max-width:677px){
    h2{
        font-size: 40px;
        text-align: center;
    }
}
@media(max-width:406px){
    .item{
        width: 100%;
    }
}
</style>
