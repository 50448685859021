<template>
    <section class="prices">
      <div class="_container">
        <h2><span>БЕТОН</span> ДЛЯ ЛЮБЫХ ЦЕЛЕЙ</h2>
        <div class="tabs">
          <div class="tabs__content">
            <div class="tab__item">
              <p class="name">КОЛЬЦО Ж/Б ДЛЯ КОЛОДЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">2600 ₽ за шт.</p>
              <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">КРЫШКА Ж/Б ДЛЯ КОЛЬЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">2500 ₽ за шт.</p>
              <button class="btn order"  @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">ДНИЩЕ Ж/Б ДЛЯ КОЛОДЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">2400 ₽ за шт.</p>
              <button class="btn order"  @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">КОЛЬЦО Ж/Б ДЛЯ КОЛОДЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">3600 ₽ за шт.</p>
              <button class="btn order"  @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">КРЫШКА Ж/Б ДЛЯ КОЛЬЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">3500 ₽ за шт.</p>
              <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">ДНИЩЕ Ж/Б ДЛЯ КОЛОДЦА</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">3400 ₽ за шт.</p>
              <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">КРЫШКА Ж/Б ДЛЯ КОЛЬЦА усиленная</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">5500 ₽ за шт.</p>
              <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
            <div class="tab__item">
              <p class="name">КРЫШКА Ж/Б ДЛЯ КОЛЬЦА усиленная</p>
              <p class="diametr">D 1,0*0,93</p>
              <p class="price">6500 ₽ за 1 м3</p>
              <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    setup() {
      const scrollToBottom = () => {
      window.scrollTo(0, document.body.scrollHeight);
    }
      return {
        scrollToBottom
      };
    },
  };
  </script>
  
  <style scoped>
  .prices {
    background: #fff;
    height: auto;
  }
  h2 {
    color: #000;
    font-size: 55px;
    font-weight: 500;
    line-height: 120%;
    margin: 20px 0;
  }
  h2 span {
    color: #c63636;
  }
  .tabs {
    display: flex;
    width: 100%;
  }

  .tabs__content {
    width: 100%;
    margin-bottom: 30px;
  }
  .tab__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: Comfortaa;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    height: auto;
  }
  .name{
    display: block;
    height: auto;
    width:300px ;
  }
  .tab__item:not(:last-child) {
    margin-bottom: 15px;
  }
  .tab__item:nth-child(2n + 1) {
    background: rgba(198, 54, 54, 0.29);
  }
  .tab__item:nth-child(2n) {
    background: rgba(243, 219, 219, 0.29);
  }

  @media (max-width: 680px) {
    h2 {
      font-size: 40px;
      text-align: center;
    }
    .tab__item {
      height: 90px;
      font-size: 17px;
      justify-content: center;
      gap: 15px;
    }
    .tab__item .btn {
      font-size: 16px;
      width: 90px;
      /* display: none; */
    }
  }
  @media (max-width: 460px) {
    .tab__item .btn {
      display: none;
    }
    .tab__item{
      gap: 20px;
    }
    .name{
        font-size: 15px;
        text-align: center;
        width: 100px;
    }
    .diametr{
        width: 70px;
    }
    .price{
        width: 100px;
    }
  }
  </style>
  